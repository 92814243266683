var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        "popper-class": "custom-ship-selector",
        disabled: _vm.disabled,
        loading: _vm.availableBargesLoading,
        placeholder: "Select",
      },
      on: {
        change: function ($event) {
          return _vm.$emit("handleChangeBarge", $event)
        },
        "visible-change": function ($event) {
          return _vm.$emit("visibleChange", $event)
        },
      },
      model: {
        value: _vm.schedulFormData.bargeName,
        callback: function ($$v) {
          _vm.$set(_vm.schedulFormData, "bargeName", $$v)
        },
        expression: "schedulFormData.bargeName",
      },
    },
    [
      _c(
        "span",
        {
          staticStyle: {
            display: "inline-block",
            width: "200px",
            "font-size": "14px",
            "font-weight": "500",
            "margin-left": "20px",
            "margin-bottom": "10px",
          },
        },
        [_vm._v("Name")]
      ),
      _c(
        "span",
        {
          staticStyle: {
            display: "inline-block",
            width: "100px",
            "font-size": "14px",
            "font-weight": "500",
          },
        },
        [_vm._v("IMO")]
      ),
      _c(
        "span",
        {
          staticStyle: {
            display: "inline-block",
            "font-size": "14px",
            "font-weight": "500",
          },
        },
        [_vm._v("SB No.")]
      ),
      _vm._l(_vm.availableBargesOptions, function (item) {
        return _c(
          "el-option",
          { key: item.id, attrs: { label: item.shipName, value: item.id } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  display: "inline-block",
                  width: "200px",
                  "font-size": "12px",
                },
              },
              [_vm._v(_vm._s(item.shipName) + " ")]
            ),
            _c(
              "span",
              {
                staticStyle: {
                  display: "inline-block",
                  width: "100px",
                  "font-size": "12px",
                },
              },
              [_vm._v(_vm._s(item.imo))]
            ),
            _c(
              "span",
              { staticStyle: { display: "inline-block", "font-size": "12px" } },
              [_vm._v(_vm._s(item.licenceNo))]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }